<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcode";
import canvasToBlob from "canvas-to-blob";
/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.devices.devicedetail"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      devicelist: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      deviceid: "",
      devicesdetail: [],
      setting: [],
      userinfo: [],
      noticelist: [],
      qrcode: "",
      deviceserver: [],
      loading: true,
      devicename_edit: false,
      devicenamevalue: "",
      systemtype: 0,
      qrCode: "",
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.devices.devicedetail");
    that.items = [
      {
        text: that.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.devices.lists"),
        href: "/device/list",
      },
      {
        text: that.$t("menuitems.devices.devicedetail"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));

    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.deviceid = that.$route.query.id;

    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "notice") {
        wsdata["time"] = Date.parse(new Date());
        wsdata["read"] = 0;
        that.noticelist.unshift(wsdata);
        localStorage.setItem("notice", JSON.stringify(that.noticelist));

        if (wsdata.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.device", { device: wsdata.edid }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t("global.notice.server." + wsdata.service),
              }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
            duration: 0,
          });
        } else if (wsdata.type2 === "stock") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message: that.$t("global.notice.stock", {
              edid: wsdata.edid,
              stock: wsdata.stock,
            }),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "sensor") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message: that.$t("global.notice.sensor", {
              edid: wsdata.edid,
              num: wsdata.num,
            }),
            type: wsdata.status,
          });
        }
      }
    };
    that.getdeviceinfo();
    that.getsysteminfo();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdeviceinfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getdeivces",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.devicesdetail = response.data.data;
          that.setting = response.data.settingv2;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    copyLink: function (dom) {
      let _this = this;
      let clipboard = new this.clipboard("." + dom);
      clipboard.on("success", function () {
        _this.$toast(_this.$t("global.copy.success"));
      });
      clipboard.on("error", function () {
        _this.$toast(_this.$t("global.copy.fail"));
      });
    },
    opendoor: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gethdinfo",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          that
            .$prompt(
              that.$t("device.detail.pleaseenterhd", {
                number: response.data.data.device.hd_num,
              }),
              that.$t("global.waring"),
              {
                confirmButtonText: that.$t("global.button.ok"),
                cancelButtonText: that.$t("global.button.cancel"),
                inputType: "number",
                inputPattern: /^[0-9]+.?[0-9]*$/,
                inputErrorMessage: that.$t("device.detail.pleaseinputtrue"),
              }
            )
            .then(({ value }) => {
              if (value > 0 && value <= response.data.data.device.hd_num) {
                that.$axios
                  .post(
                    that.apiuri,
                    {
                      action: "opendoor",
                      id: id,
                      hd: value,
                    },
                    {
                      headers: { Openid: that.userinfo.openid },
                    }
                  )
                  .then(function (response) {
                    that.$toast.clear();
                    if (response.data.status == 200) {
                      that.$message({
                        message: that.$t("global.success"),
                        type: "success",
                      });
                    } else if (response.data.status == 207) {
                      that.$message.error(that.$t("device.lists.offline"));
                    } else {
                      that.$message.error(that.$t("global.delete.500"));
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              } else {
                that.$message.error(that.$t("device.detail.pleaseinputtrue"));
              }
            })
            .catch(() => {});
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closedoor: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gethdinfo",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          that
            .$prompt(
              that.$t("device.detail.pleaseenterhd", {
                number: response.data.data.device.hd_num,
              }),
              that.$t("global.waring"),
              {
                confirmButtonText: that.$t("global.button.ok"),
                cancelButtonText: that.$t("global.button.cancel"),
                inputType: "number",
                inputPattern: /^[0-9]+.?[0-9]*$/,
                inputErrorMessage: that.$t("device.detail.pleaseinputtrue"),
              }
            )
            .then(({ value }) => {
              if (value > 0 && value <= response.data.data.device.hd_num) {
                that.$axios
                  .post(
                    that.apiuri,
                    {
                      action: "closedoor",
                      id: id,
                      hd: value,
                    },
                    {
                      headers: { Openid: that.userinfo.openid },
                    }
                  )
                  .then(function (response) {
                    that.$toast.clear();
                    if (response.data.status == 200) {
                      that.$message({
                        message: that.$t("global.success"),
                        type: "success",
                      });
                    } else if (response.data.status == 207) {
                      that.$message.error(that.$t("device.lists.offline"));
                    } else {
                      that.$message.error(that.$t("global.delete.500"));
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              } else {
                that.$message.error(that.$t("device.detail.pleaseinputtrue"));
              }
            })
            .catch(() => {});
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    restart: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.restarttip"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              that.apiuri,
              {
                action: "restart",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 207) {
                that.$message.error(that.$t("device.lists.offline"));
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    maintenance: function (action, id) {
      var that = this;
      if (action == "open") {
        var tips = that.$t("device.detail.openmaintenance");
      } else {
        var tips = that.$t("device.detail.closemaintenance");
      }
      that
        .$confirm(that.$t("global.waring"), tips, {
          confirmButtonText: that.$t("global.button.ok"),
          cancelButtonText: that.$t("global.button.cancel"),
          type: "warning",
        })
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              that.apiuri,
              {
                action: "maintenance",
                jofs: action,
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              that.getdeviceinfo();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 207) {
                that.$message.error(that.$t("device.lists.offline"));
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    showstockresetlog: function (id) {},
    showmaintainlog: function (id) {},
    getwechatqrcode: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwechatqrcode",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$alert(
              "<img src='https://paygay.aic.xin/qr.png?data=" +
                response.data.img +
                "' width='100%'/>",
              that.$t("device.button.qrcode"),
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showdeviceqrdiy: function () {
      var that = this;
      that.$alert(
        "<img src='https://paygay.aic.xin/qr.png?data=https://mdy.aicoiot.beer/user" +
          that.userinfo.id +
          ".html?sn=" +
          that.devicesdetail.device_sn +
          "' width='100%'/>",
        that.$t("device.button.qrcode"),
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    showdeviceqr: function () {
      var that = this;
      that.$alert(
        "<img src='https://paygay.aic.xin/qr.png?data=https://menu.aicoiot.beer/" +
          that.devicesdetail.device_sn +
          ".memu' width='300px' height='300px'/>",
        that.$t("device.button.qrcode"),
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    opensharing: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          that.apiuri,
          {
            action: "switchshring",
            type: "open",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.getdeviceinfo();
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closesharing: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          that.apiuri,
          {
            action: "switchshring",
            type: "close",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.getdeviceinfo();
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    complex: function (id) {},
    itialization: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.itialization"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              that.apiuri,
              {
                action: "itialization",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.failed"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    opensafedoor: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.openthesafedoor"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              that.apiuri,
              {
                action: "opensafedoor",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.failed"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    changemode: function (id, type) {
      var that = this;
      if (type == "pay") {
        var modetext = that.$t("device.detail.switchpaymentmode");
      } else {
        var modetext = that.$t("device.detail.switchhandemode");
      }
      that
        .$confirm(modetext, that.$t("global.waring"), {
          confirmButtonText: that.$t("global.button.ok"),
          cancelButtonText: that.$t("global.button.cancel"),
          type: "warning",
        })
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              that.apiuri,
              { action: "changemode", ED_id: id, type: type },
              { headers: { Openid: that.userinfo.openid } }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.failed"));
              }
            });
        });
    },
    changedeviceserver: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });

      that.$axios
        .post(
          that.apiuri,
          {
            action: "getnowserver",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.deviceserver = response.data.data;
            that.$alert("", that.$t("device.detail.changeserver"), {
              dangerouslyUseHTMLString: true,
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getipinfos: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getclientip",
            edid: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.deviceserver = response.data.data;
            that.$alert(
              that.$t("device.detail.country") +
                "：" +
                response.data.data.country +
                "<br/>" +
                that.$t("device.detail.area") +
                "：" +
                response.data.data.area +
                "<br/>" +
                that.$t("device.detail.region") +
                "：" +
                response.data.data.region +
                "<br/>" +
                that.$t("device.detail.city") +
                "：" +
                response.data.data.city +
                "<br/>" +
                that.$t("device.detail.county") +
                "：" +
                response.data.data.county +
                "<br/>" +
                that.$t("device.detail.isp") +
                "：" +
                response.data.data.isp +
                "<br/>" +
                that.$t("device.detail.ip") +
                "：" +
                response.data.data.ip,
              that.$t("device.detail.ip"),
              {
                dangerouslyUseHTMLString: true,
              }
            );
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    commanddebug: function (id) {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getdeviceserror",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$toast.loading({
              message: that.$t("global.loading.wating"),
              forbidClick: true,
            });
            setTimeout(function () {
              that.getdeviceinfo();
              that.$toast.clear();
            }, 5000);
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    devicecheckout(id) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "barcheckout",
            id: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if (response.data.status == 404) {
            that.$message.error(that.$t("device.lists.offline"));
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changedevicename() {
      // this.$t("menuitems.marketing.micoapp.text")
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savedeviceinfo",
            id: that.deviceid,
            key: "name",
            value: that.devicenamevalue,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.devicename_edit = false;
          if (response.data.status == 200) {
            that.getdeviceinfo();
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updategoodsinfo(device) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "updategoods",
            id: device,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updatedevicestatus(device) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "updatedevicestatus",
            id: device,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async showqrcode(devicesn) {
      let hostname = document.location.hostname;

      const canvas = this.$refs.canvas;
      const options = { width: 256, height: 256 };
      const dataUrl = await QRCode.toDataURL(
        "https://cashier" + hostname + "/?sn=" + devicesn,
        options
      );
      canvas.width = options.width;
      canvas.height = options.height;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, options.width, options.height);
        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.qrCode = reader.result;
          };
          reader.readAsDataURL(blob);
        });
      };
      img.src = dataUrl;

      // console.log(this.$refs.canvas)
      this.$alert(
        '<img width="100%" src="' + img.src + '">',
        this.$t("device.button.qrcode"),
        {
          confirmButtonText: this.$t("global.button.ok"),
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            if (action === "confirm") {
              let uri = "https://cashier" + hostname + "/?sn=" + devicesn;
              this.$axios
                .post(
                  this.apiuri,
                  {
                    action: "updatedeviceqrcode",
                    id: this.devicesdetail.ED_id,
                    uri
                  },
                  {
                    headers: { Openid: this.userinfo.openid },
                  }
                )
                .then(function (response) {
                  if (response.data.status == 200) {
                    this.$message({
                      message: this.$t("global.success"),
                      type: "success",
                    });
                  } else {
                    this.$message.error(this.$t("global.delete.500"));
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          },
        }
      );
    },
    resetdevicepwd: function (id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.waring"),
          that.$t("device.detail.resetdevicepwd"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$toast.loading({
            message: that.$t("global.loading.reading"),
            forbidClick: true,
          });
          that.$axios
            .post(
              that.apiuri,
              {
                action: "resetdevicepwd",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.$toast.clear();
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <canvas style="display: none" ref="canvas"></canvas>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="col-lg-6" style="float: left">
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.id") }}</span>
                <span
                  class="float-right text-muted copyid"
                  :data-clipboard-text="devicesdetail.ED_id"
                  @click="copyLink('copyid')"
                  >{{ devicesdetail.ED_id }}</span
                >
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.sn") }}</span>
                <span
                  class="float-right text-muted copysn"
                  :data-clipboard-text="devicesdetail.device_sn"
                  @click="copyLink('copysn')"
                  >{{ devicesdetail.device_sn }}</span
                >
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.name") }}</span>
                <span
                  class="float-right text-muted"
                  v-if="devicename_edit == false"
                  @click="devicename_edit = true"
                  >{{ devicesdetail.name }}</span
                >
                <span
                  class="float-right text-muted"
                  v-if="devicename_edit == true"
                >
                  <el-input
                    v-model="devicenamevalue"
                    :placeholder="$t('device.detail.inputdevicename')"
                  ></el-input>
                  <el-button
                    type="success"
                    icon="el-icon-check"
                    circle
                    @click="changedevicename"
                  ></el-button>
                </span>
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.mark") }}</span>
                <span class="float-right text-muted">{{
                  devicesdetail.remark
                }}</span>
              </p>
              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.note") }}</span>
                <span class="float-right text-muted">{{
                  devicesdetail.note
                }}</span>
              </p>

              <p class="clearfix">
                <span class="float-left">{{ $t("device.detail.error") }}</span>
                <span class="float-right">
                  <label
                    class="badge badge-danger"
                    v-if="devicesdetail.error_code == 31"
                    >{{ $t("device.error.error31") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 32"
                    >{{ $t("device.error.error32") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 33"
                    >{{ $t("device.error.error33") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 34"
                    >{{ $t("device.error.error34") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 35"
                    >{{ $t("device.error.error35") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 36"
                    >{{ $t("device.error.error36") }}</label
                  >
                  <label
                    class="badge badge-danger"
                    v-else-if="devicesdetail.error_code == 37"
                    >{{ $t("device.error.error37") }}</label
                  >
                  <label class="badge badge-success" v-else>{{
                    $t("device.error.true")
                  }}</label>
                </span>
              </p>
            </div>
            <div
              class="col-lg-6 button-items"
              style="float: left"
              v-if="systemtype == 0 || systemtype == 2"
            >
              <!-- 7200 -->
              <div v-if="devicesdetail.subid == 7200">
                <button
                  class="btn btn-info mr-2"
                  @click="opendoor(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openvavl") }}
                </button>
                <button
                  class="btn btn-info mr-2"
                  @click="closedoor(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closevavl") }}
                </button>
              </div>
              <!-- 7100 -->
              <div v-else-if="devicesdetail.subid == 7100"></div>
              <!-- 7620 -->
              <div v-else-if="devicesdetail.subid == 7620">
                <button
                  class="btn btn-info mr-2"
                  @click="opendoor(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openvavl") }}
                </button>
                <button
                  class="btn btn-info mr-2"
                  @click="closedoor(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closevavl") }}
                </button>
                <br />
                <router-link
                  :to="{ path: '/device/hd', query: { id: devicesdetail.id } }"
                >
                  <a class="btn btn-info">
                    {{ $t("device.button.hdmanage") }}
                  </a>
                </router-link>
                <router-link
                  :to="{
                    path: '/device/stock',
                    query: { id: devicesdetail.id },
                  }"
                >
                  <a class="btn btn-info btn-fwo">
                    {{ $t("device.button.stockmanage") }}
                  </a>
                </router-link>
                <br />
                <button
                  class="btn btn-info mr-2"
                  @click="itialization(devicesdetail.id)"
                >
                  {{ $t("device.button.startup") }}
                </button>
                <button
                  class="btn btn-info mr-2"
                  @click="opensafedoor(devicesdetail.id)"
                >
                  {{ $t("device.button.opendoor") }}
                </button>
                <br />
                <b-dropdown variant="secondary">
                  <template v-slot:button-content>
                    {{ $t("device.button.other") }}
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changemode(devicesdetail.ED_id, 'pay')"
                    >{{ $t("device.button.modechange") }}-{{
                      $t("device.button.payment")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changemode(devicesdetail.ED_id, 'hande')"
                    >{{ $t("device.button.modechange") }}-{{
                      $t("device.button.handmode")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changelang(devicesdetail.ED_id, 1)"
                    >{{ $t("device.button.language") }}-{{
                      $t("device.button.chs")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="changelang(devicesdetail.ED_id, 2)"
                    >{{ $t("device.button.language") }}-{{
                      $t("device.button.en")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="expow(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.sealpower")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="expre(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.pressureout")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="inpre(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.pressurein")
                    }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript: void(0);"
                    @click="refushsetting(devicesdetail.ED_id)"
                    >{{ $t("device.button.setting") }}-{{
                      $t("device.button.refresh")
                    }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div v-else-if="devicesdetail.subid == 7710">
                <button
                  class="btn btn-info mr-2"
                  @click="updategoodsinfo(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.updategoods") }}
                </button>
                <button
                  class="btn btn-success"
                  @click="updatedevicestatus(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.status") }}
                </button>
                <br />
                <router-link
                  :to="{ path: '/device/hd', query: { id: devicesdetail.id } }"
                >
                  <a class="btn btn-info">
                    {{ $t("device.button.hdmanage") }}
                  </a>
                </router-link>
                <router-link
                  :to="{
                    path: '/device/stock',
                    query: { id: devicesdetail.id },
                  }"
                >
                  <a class="btn btn-info btn-fwo">
                    {{ $t("device.button.stockmanage") }}
                  </a>
                </router-link>

                <br />
                <button
                  class="btn btn-warning"
                  @click="restart(devicesdetail.id)"
                >
                  <i class="mdi mdi-backup-restore mr-2"></i>
                  {{ $t("device.button.restart") }}
                </button>
              </div>
              <div v-else-if="devicesdetail.subid == 7800">
                <router-link
                  :to="{ path: '/device/cocktailsetting', query: { id: devicesdetail.id } }"
                >
                  <a class="btn btn-info">
                    {{ $t("device.button.cocktailsetting") }}
                  </a>
                </router-link>
                <br />
                <button
                  class="btn btn-warning"
                  @click="restart(devicesdetail.id)"
                >
                  <i class="mdi mdi-backup-restore mr-2"></i>
                  {{ $t("device.button.restart") }}
                </button>
              </div>
              <!-- Else -->
              <div v-else>
                <button
                  class="btn btn-success"
                  @click="opendoor(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openvavl") }}
                </button>
                <button
                  class="btn btn-success"
                  @click="closedoor(devicesdetail.id)"
                >
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closevavl") }}
                </button>
                <br />
                <router-link
                  :to="{ path: '/device/hd', query: { id: devicesdetail.id } }"
                >
                  <a class="btn btn-info">
                    {{ $t("device.button.hdmanage") }}
                  </a>
                </router-link>
                <router-link
                  :to="{
                    path: '/device/stock',
                    query: { id: devicesdetail.id },
                  }"
                >
                  <a class="btn btn-info btn-fwo">
                    {{ $t("device.button.stockmanage") }}
                  </a>
                </router-link>

                <br />
                <button
                  class="btn btn-warning"
                  @click="restart(devicesdetail.id)"
                >
                  <i class="mdi mdi-backup-restore mr-2"></i>
                  {{ $t("device.button.restart") }}
                </button>
                <br />
                <button
                  class="btn btn-info"
                  @click="resetdevicepwd(devicesdetail.id)"
                >
                  <i class="mdi mdi-backup-restore mr-2"></i>
                  {{ $t("device.button.resetpwd") }}
                </button>
                <br />
                <button
                  class="btn btn-info btn-fwo"
                  @click="showqrcode(devicesdetail.device_sn)"
                >
                  <i class="mdi mdi-backup-restore mr-2"></i>
                  {{ $t("device.button.qrcode") }}
                </button>
              </div>
            </div>
            <div
              class="col-lg-6 button-items"
              style="float: left"
              v-if="systemtype == 1"
            >
              <router-link
                :to="{
                  path: '/device/baropen',
                  query: { id: devicesdetail.id },
                }"
              >
                <a class="btn btn-success" href="javascript:;">
                  <i class="mdi mdi-flash mr-2"></i>
                  {{ $t("device.button.openstage") }}
                </a>
              </router-link>

              <router-link
                :to="{
                  path: '/device/barclose',
                  query: { id: devicesdetail.id },
                }"
              >
                <a class="btn btn-success" href="javascript:;">
                  <i class="mdi mdi-flash-off mr-2"></i>
                  {{ $t("device.button.closestage") }}
                </a>
              </router-link>
              <br />
              <router-link
                :to="{ path: '/device/hd', query: { id: devicesdetail.id } }"
              >
                <a class="btn btn-info">
                  {{ $t("device.button.hdmanage") }}
                </a>
              </router-link>
              <router-link
                :to="{
                  path: '/device/stock',
                  query: { id: devicesdetail.id },
                }"
              >
                <a class="btn btn-info btn-fwo">
                  {{ $t("device.button.stockmanage") }}
                </a>
              </router-link>
              <button
                type="button"
                class="btn btn-info"
                v-if="systemtype == 1"
                @click="devicecheckout(devicesdetail.id)"
              >
                {{ $t("device.button.checkout") }}
              </button>
              <br />
              <button
                class="btn btn-warning"
                @click="restart(devicesdetail.id)"
              >
                <i class="mdi mdi-backup-restore mr-2"></i>
                {{ $t("device.button.restart") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>